import React, { Component } from "react";
import "../App.css";

import { Row, Col, Card, Button } from "react-bootstrap";
import AfficheTri from "../images/affichetri2024.jpg";
import AfficheFFTRI from "../images/presentation.jpg";

class Sommaire extends Component {
	render() {
		return (
			<div className="Sommaire">
				<Row>
					<Col xs={12} md={6} lg={4} className="Card-container">
						<Card className="CardStyle Card-ombre">
							<div className="CardIMG">
								<Card.Img
									className="Cardimg"
									variant="top"
									src={AfficheFFTRI}
									alt="Affiche FFTRI"
								/>
							</div>
							<Card.Body>
								<Card.Title className="CardTitre blue">
									Présentation
								</Card.Title>

								<Card.Text className="justify blue">
									Venez découvrir notre offre d'entrainement
									pour adulte mais aussi l'école de triathlon
									pour les jeunes ainsi que les membres du
									bureau et son projet associatif.
								</Card.Text>
								<Button
									variant="secondary BoutonAccueil"
									href="/club"
								>
									Découvrir le club
								</Button>
							</Card.Body>
						</Card>
					</Col>

					<Col xs={12} md={6} lg={4} className="Card-container">
						<Card className="CardStyle Card-ombre">
							<div className="CardIMG">
								<Card.Img
									className="Cardimg"
									variant="top"
									src={AfficheTri}
                                    alt="Affiche Triathlon 2024"
								/>
							</div>
							<Card.Body>
								<Card.Title className="CardTitre blue">
									Organisations
								</Card.Title>

								<Card.Text className="justify blue">
									Chaque année, Sens Triathlon vous propose un
									triathlon et un Bike&Run. C'est ici que vous
									retrouverez les horaires, réglements,
									parcours...
								</Card.Text>
								<Button
									variant="secondary BoutonAccueil"
									href="/triathlon"
								>
									Découvrir nos événements
								</Button>
							</Card.Body>
						</Card>
					</Col>

					<Col xs={12} md={12} lg={4} className="Facebook-container">
						<div
							className="fb-page Card-ombre"
							data-href="https://www.facebook.com/sens.triathlon"
							data-tabs="timeline"
							data-width="500"
							data-height="600"
							data-small-header="true"
							data-adapt-container-width="true"
							data-hide-cover="true"
							data-show-facepile="false"
						>
							<blockquote
								cite="https://www.facebook.com/sens.triathlon"
								className="fb-xfbml-parse-ignore"
							>
								<a href="https://www.facebook.com/sens.triathlon">
									Sens Triathlon
								</a>
							</blockquote>
						</div>
					</Col>
				</Row>
			</div>
		);
	}
}

export default Sommaire;
