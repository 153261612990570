import React from "react";
import { Row, Col } from "react-bootstrap";
import "../App.css";
import Header from "./Header";
import Footer from "./Footer";
import AfficheBR from "../../src/images/BR2023affiche.jpg";
import ResXS from "../documents/ResXS.pdf";
import ResJ1 from "../documents/ResJ1.pdf";
import ResJ2 from "../documents/ResJ2.pdf";
import ResXS2023 from "../documents/resultats_XS.pdf";
import ResJ12023 from "../documents/resultats_J1.pdf";
import ResJ22023 from "../documents/resultats_J2.pdf";
//import ParcoursBandR from "../images/parcoursB&R.jpg";
import Sponsor1 from "../images/kiwamis.jpg";
import Sponsor2 from "../images/leclercnord.jpg";
import Sponsor3 from "../images/brasseriemonclem.jpg";

function BikeAndRun() {
	var themeRec = localStorage.getItem("theme");
	var theme2 = themeRec.substr(1);
	var theme = theme2.substring(0, theme2.length - 1);

	return (
		<div className="Organisations" data-theme={theme}>
			<Header />

			<div className="BikeAndRun" id="bikeandrun">
				<h1>Bike & Run de Sens</h1>
				<hr />

				<Row>
					<Col xs={12} md={12} lg={2}></Col>
					<Col xs={12} md={6} lg={4}>
						<img
							className="affiche-triathlon"
							alt="Affiche bike and run"
							src={AfficheBR}
						/>
					</Col>
					<Col xs={12} md={6} lg={4} className="InscriptionBR">
						<h2 className="pink left" id="inscriptions">
							Inscriptions
						</h2>
						<div className="bluehr"></div>
						{/* <p className="pink">Ouverture prochainement...</p> */}

						<a
							className="pink"
							href="https://www.helloasso.com/associations/sens-triathlon/evenements/bike-and-run-du-senonais"
							target="_blank"
							rel="noopener noreferrer"
						>
							Inscriptions en ligne
						</a>
						<br /><br />
						<a
							className="pink"
							href="https://espacetri.fftri.com/pass-competition/event/pass-competition/11849"
							target="_blank"
							rel="noopener noreferrer"
						>
							Pass compétition à remplir pour les non-licenciés
						</a>

						<h2 className="pink left" id="inscriptions">
							Résultats 2023
						</h2>
						<div className="bluehr"></div>

						<p>
							<a
								className="pink"
								href={ResJ12023}
								target="_blank"
								rel="noopener noreferrer"
							>
								Jeune 1
							</a>{" "}
						</p>
						<p>
							<a
								className="pink"
								href={ResJ22023}
								target="_blank"
								rel="noopener noreferrer"
							>
								Jeune 2
							</a>
						</p>
						<p>
							<a
								className="pink"
								href={ResXS2023}
								target="_blank"
								rel="noopener noreferrer"
							>
								Course XS
							</a>
						</p>


						<h2 className="pink left" id="inscriptions">
							Résultats 2022
						</h2>
						<div className="bluehr"></div>

						<p>
							<a
								className="pink"
								href={ResJ1}
								target="_blank"
								rel="noopener noreferrer"
							>
								Jeune 1
							</a>{" "}
						</p>
						<p>
							<a
								className="pink"
								href={ResJ2}
								target="_blank"
								rel="noopener noreferrer"
							>
								Jeune 2
							</a>
						</p>
						<p>
							<a
								className="pink"
								href={ResXS}
								target="_blank"
								rel="noopener noreferrer"
							>
								Course XS
							</a>
						</p>
					</Col>
					<Col xs={12} md={12} lg={2}></Col>
				</Row>
				{/* <Row>
					<Col xs={12} md={12} lg={4}></Col>
					<Col xs={12} md={12} lg={4}>
						<h2 className="pink left center-title">Parcours</h2>
						<div className="bluehr center-title"></div>
					</Col>
					<Col xs={12} md={12} lg={4}></Col>
				</Row>
				<Row>
					<Col xs={12} md={12} lg={2}></Col>
					<Col xs={12} md={12} lg={8}>
						<img
							className="parcours-br"
							alt="Parcours bike and run"
							src={ParcoursBandR}
						/>
					</Col>
					<Col xs={12} md={12} lg={2}></Col>
				</Row> */}
				<Row>
					<Col xs={12} md={12} lg={4}>
						
					</Col>
					<Col xs={12} md={12} lg={4}>
						<h2 className="pink left center-title">Partenaires</h2>
						<div className="bluehr center-title"></div>
						
					</Col>
					<Col xs={12} md={12} lg={4}>
						
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={12} lg={4}>
						<img
							className="sponsor-br"
							alt="Parcours bike and run"
							src={Sponsor2}
							
						/>
					</Col>
					<Col xs={12} md={12} lg={4}>
						
						<img
							className="sponsor-br"
							alt="Parcours bike and run"
							src={Sponsor1}
							
						/>
					</Col>
					<Col xs={12} md={12} lg={4}>
						<img
							className="sponsor-br"
							alt="Parcours bike and run"
							src={Sponsor3}
							
						/>
						<p className="pink left center-title">
							Brasserie monclem
						</p>
					</Col>
				</Row>
			</div>
			<Footer />
		</div>
	);
}

export default BikeAndRun;
